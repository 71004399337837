import React from 'react';

import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Layout from '@/components/Layout';

const Imprint = () => (
  <Layout className="layout__background">
    <div className="imprint__container">
      <Header />
      <div className="imprint__wrapper">
        <h1 className="imprint__title">Imprint</h1>
        <div className="imprint__content">
          <div className="imprint__company-wrapper">
            <h2 className="imprint__company">thirty3 GmbH</h2>
            <article className="imprint__address">
              <p>Nordstrasse 198</p>
              <p>8037 Zürich</p>
              <p>Schweiz</p>
            </article>
          </div>
          <article className="imprint__management">
            <p>UID: CHE-327.178.997</p>
            <p>CH-ID: CH02040692426</p>
            <p>Executive Management: Sylvestre Lucia</p>
          </article>
        </div>
      </div>
      <Footer />
    </div>
  </Layout>
);

export default Imprint;
